import React, { useState, useEffect, useContext } from "react";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from "common";
import { useTranslation } from "react-i18next";
import { lightGreen } from "@mui/material/colors";
import { HdSharp } from "@mui/icons-material";

const TripTypes = () => {
  const { editTripTypes, fetchTripTypes } = api;
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const rtl = {
    rl: isRTL === "rtl" ? "right" : "left",
  };
  const columns = [
    {
      title: t("trip_type"),
      field: "name",
      render: (rowData) => <span>{rowData.name}</span>,
      cellStyle: { textAlign: rtl.rl },
      headerStyle: { textAlign: rtl.rl },
    },
    {
      title: t("arabicName"),
      field: "arabicName",
      render: (rowData) => <span>{rowData.arabicName}</span>,
      cellStyle: { textAlign: rtl.rl },
      headerStyle: { textAlign: rtl.rl },
    },
    {
      title: t("englishName"),
      field: "englishName",
      render: (rowData) => <span>{rowData.englishName}</span>,
      cellStyle: { textAlign: rtl.rl },
      headerStyle: { textAlign: rtl.rl },
    },
    {
      title: t("kurdishName"),
      field: "kurdishName",
      render: (rowData) => <span>{rowData.kurdishName}</span>,
      cellStyle: { textAlign: rtl.rl },
      headerStyle: { textAlign: rtl.rl },
    },
  ];
  const settings = useSelector((state) => state.settingsdata.settings);
  const [data, setData] = useState([]);
  const tripTypes = useSelector((state) => state.tripdata.trips);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTripTypes());
  }, [dispatch, fetchTripTypes]);

  useEffect(() => {
    if (tripTypes.data) {
      setData(tripTypes.data);
    } else {
      setData([]);
    }
  }, [tripTypes]);

  return tripTypes.loading ? (
    <CircularLoading />
  ) : (
    <MaterialTable
      title={t("trip_types")}
      columns={columns}
      icons={{
        Check: () => <>√</>,
        ThirdStateCheck: () => <>X</>,
      }}
      data={data}
      options={{
        exportButton: true,
        pageSize: 10,
      }}
      editable={
        settings.AllowCriticalEditsAdmin
          ? {
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    const tblData = data;
                    newData.value = tblData.length;
                    tblData.push(newData);
                    dispatch(editTripTypes(tblData, "Add"));
                  }, 600);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    const tblData = data;
                    tblData[tblData.indexOf(oldData)] = newData;
                    dispatch(editTripTypes(tblData, "Update"));
                  }, 600);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    const tblData = data;
                    tblData.splice(tblData.indexOf(oldData), 1);
                    for (let i = 0; i < tblData.length; i++) {
                      tblData[i].value = i;
                    }
                    dispatch(editTripTypes(tblData, "Delete"));
                  }, 600);
                }),
            }
          : null
      }
    />
  );
};

export default TripTypes;
