import { PMODAL_FAILED, PMODAL_REQUEST, PMODAL_SUCCESS } from "../store/types";

const INITIAL_STATE = {
  loading: false,
  data: [],
  err: "",
};

export const pmodalreducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PMODAL_REQUEST:
      return { loading: true };
    case PMODAL_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case PMODAL_FAILED:
      return { ...state, loading: false, err: action.payload };
    default:
      return state;
  }
};
