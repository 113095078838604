import {
  UPDATE_TRIP_PICKUP,
  UPDATE_TRIP_DROP,
  UPDATE_TRIP_CAR,
  UPDATE_SELECTED_POINT_TYPE,
  CLEAR_TRIP_POINTS,
  //saja//
  FETCH_TRIP_TYPES_REQUEST,
  FOUND_TRIP_TYPE,
  NOTFOUND_TRIP_TYPE,
  FETCH_TRIP_TYPES_SUCCESS,
  FETCH_TRIP_TYPES_FAILED,
  EDIT_TRIP_TYPE,
  //end//
} from "../store/types";

const INITIAL_STATE = {
  pickup: null,
  drop: null,
  carType: null,
  selected: "pickup",
};

export const tripreducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_TRIP_PICKUP:
      return {
        ...state,
        pickup: action.payload,
      };
    case UPDATE_TRIP_DROP:
      return {
        ...state,
        drop: action.payload,
      };
    case UPDATE_TRIP_CAR:
      return {
        ...state,
        carType: action.payload,
      };
    case UPDATE_SELECTED_POINT_TYPE:
      return {
        ...state,
        selected: action.payload,
      };
    case CLEAR_TRIP_POINTS:
      return {
        ...state,
        drop: {
          lat: state.pickup.lat,
          lng: state.pickup.lng,
          add: "",
        },
        carType: null,
        selected: "pickup",
      };
    /////Saja Change *Start*\\\\\
    case FETCH_TRIP_TYPES_REQUEST:
      return { ...state, trips: { loading: true } };
    case FOUND_TRIP_TYPE:
    case NOTFOUND_TRIP_TYPE:
      return { ...state, trips: { loading: false } };
    case FETCH_TRIP_TYPES_SUCCESS:
      return {
        ...state,
        trips: { ...state.trips, loading: false, data: action.payload },
      };
    case FETCH_TRIP_TYPES_FAILED:
      return {
        ...state,
        trips: {
          ...state.trips,
          loading: false,
          error: { flag: true, msg: action.payload },
        },
      };
    case EDIT_TRIP_TYPE:
    /////Saja Change *end*\\\\\
    default:
      return state;
  }
};
