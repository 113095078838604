import {
  EDIT_PMODAL,
  EDIT_PMODAL_RANGES,
  PMODAL_FAILED,
  PMODAL_REQUEST,
  PMODAL_SUCCESS,
} from "../store/types";
import { firebase } from "../config/configureFirebase";
import { onValue, set, remove } from "firebase/database";

export const fetchModals = (nameOnly) => async (dispatch) => {
  const { pModalRef } = firebase;

  dispatch({ type: PMODAL_REQUEST });
  // const data = await pModalRef.once("value");
  onValue(pModalRef, (snapshot) => {
    // if (snapshot.val()) {
    // pModalRef.on("value", (snapshot) => {
    const data = snapshot.val();
    const isNumber = (testSubject) => {
      const type = typeof testSubject;
      switch (type) {
        case "number":
          return testSubject >= 0;
        case "string":
          return Number(testSubject) >= 0;
        default:
          return NaN;
      }
    };
    if (data) {
      const modals = [];
      for (let modalId in data) {
        const { name, ranges } = data[modalId];
        const modal = {};
        if (name) modal.name = name;
        if (nameOnly) {
          modals.push(modal);
          continue;
        }
        modal.modalId = modalId;
        if (ranges) {
          for (let rangeKey in ranges) {
            const { from, to, price, total } = ranges[rangeKey];
            if (!total) {
              const areNumbers =
                isNumber(to) && isNumber(from) && isNumber(price);
              ranges[rangeKey].total = areNumbers ? (to - from) * price : 0;
            }
          }
          modal.ranges = Object.values(ranges);
        }
        modals.push(modal);
      }
      dispatch({ type: PMODAL_SUCCESS, payload: modals });
    } else {
      dispatch({ type: PMODAL_FAILED, payload: "None found" });
    }
  });
};

export const editModals = (modal, method) => (dispatch) => {
  const { pModalRef } = firebase;

  dispatch({ type: EDIT_PMODAL, payload: method });
  // delete modal.modalId;
  // remove(pModalRef(modal.modalId));
  // pModalRef.set(modal);
  set(pModalRef, modal);
};

export const editModalRanges = (modal, method) => (dispatch) => {
  const { pModalRangesEditRef } = firebase;
  dispatch({ type: EDIT_PMODAL_RANGES, payload: method });
  const id = modal.modalId;
  // delete modal.modalId;
  remove(pModalRangesEditRef(modal.modalId));
  // pModalRangesEditRef(id).set(modal.ranges);
  let data = modal.ranges;
  set(pModalRangesEditRef(id), data);
};
