import {
  UPDATE_TRIP_PICKUP,
  UPDATE_TRIP_DROP,
  UPDATE_TRIP_CAR,
  UPDATE_SELECTED_POINT_TYPE,
  CLEAR_TRIP_POINTS,
  //saja//
  EDIT_TRIP_TYPE,
  FETCH_TRIP_TYPES_FAILED,
  FETCH_TRIP_TYPES_SUCCESS,
  FETCH_TRIP_TYPES_REQUEST,
  //end//
} from "../store/types";
//saja//
import store from "../store/store";
import { firebase } from "../config/configureFirebase";
import { onValue, set } from "firebase/database";
//end//

export const updateTripPickup = (pickupAddress) => (dispatch) => {
  dispatch({
    type: UPDATE_TRIP_PICKUP,
    payload: pickupAddress,
  });
};

export const updateTripDrop = (dropAddress) => (dispatch) => {
  dispatch({
    type: UPDATE_TRIP_DROP,
    payload: dropAddress,
  });
};

export const updateTripCar = (selectedCar) => (dispatch) => {
  dispatch({
    type: UPDATE_TRIP_CAR,
    payload: selectedCar,
  });
};

export const updatSelPointType = (selection) => (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_POINT_TYPE,
    payload: selection,
  });
};

export const clearTripPoints = () => (dispatch) => {
  dispatch({
    type: CLEAR_TRIP_POINTS,
    payload: null,
  });
};

/////Saja Change *Start* added trip types\\\\\
export const fetchTripTypes = () => (dispatch) => {
  const { tripTypesRef } = firebase;

  dispatch({ type: FETCH_TRIP_TYPES_REQUEST });

  onValue(tripTypesRef, (snapshot) => {
    // tripTypesRef.on("value", (snapshot) => {
    const data = snapshot.val();
    if (data) {
      const tripTypes = Object.values(data);
      dispatch({
        type: FETCH_TRIP_TYPES_SUCCESS,
        payload: tripTypes,
      });
    } else {
      dispatch({
        type: FETCH_TRIP_TYPES_FAILED,
        payload: store.getState().languagedata.defaultLanguage.no_trips_found,
      });
    }
  });
};

export const editTripTypes = (tripsData, method) => (dispatch) => {
  const { tripTypesRef } = firebase;
  dispatch({ type: EDIT_TRIP_TYPE, payload: method });
  // tripTypesRef.set(tripsData);
  set(tripTypesRef, tripsData);
  /*  if (method === "Add") {
          set(tripTypesRef, tripsData);
        } else if (method === "Delete") {
          remove(tripTypesEditRef(tripsData.value));
        } else {
          update(tripTypesEditRef(tripsData.value), tripsData);
        } */
};

/////*end*\\\\\
