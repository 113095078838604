import React, { useState, useEffect, useRef } from "react";
import { downloadCsv } from "../common/sharedFunctions";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from "common";
import { useTranslation } from "react-i18next";
import moment from "moment/min/moment-with-locales";
import { colors } from "../components/Theme/WebTheme";

export default function Agents() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector((state) => state.settingsdata.settings);
  const { addUser, editUser, deleteUser, checkUserExists } = api;
  const [data, setData] = useState([]);
  const usersdata = useSelector((state) => state.usersdata);
  const dispatch = useDispatch();

  useEffect(() => {
    if (usersdata.users) {
      setData(usersdata.users.filter((user) => user.usertype === "agent"));
    } else {
      setData([]);
    }
  }, [usersdata.users]);

  const columns = [
    {
      title: t("createdAt"),
      field: "createdAt",
      editable: "never",
      defaultSort: "desc",
      render: (rowData) =>
        rowData.createdAt ? moment(rowData.createdAt).format("lll") : null,
    },
    {
      title: t("first_name"),
      field: "firstName",
      initialEditValue: "",
      cellStyle: { paddingLeft: isRTL === "rtl" ? 50 : 15 },
    },
    {
      title: t("last_name"),
      field: "lastName",
      initialEditValue: "",
      cellStyle: { paddingLeft: isRTL === "rtl" ? 30 : 15 },
    },
    {
      title: t("email"),
      field: "email",
      editable: "onAdd",
      render: (rowData) =>
        settings.AllowCriticalEditsAdmin ? rowData.email : "Hidden for Demo",
      headerStyle: { textAlign: "center" },
    },
    {
      title: t("mobile"),
      field: "mobile",
      editable: "onAdd",
      render: (rowData) =>
        settings.AllowCriticalEditsAdmin ? rowData.mobile : "Hidden for Demo",
    },
    {
      title: t("personal_image"),
      field: "personalImage",
      render: (rowData) =>
        rowData.personalImage ? (
          <img
            alt="Profile"
            src={rowData.personalImage}
            style={{ width: 50, borderRadius: "50%" }}
          />
        ) : null,
      editable: "never",
      cellStyle: { textAlign: "center" },
    },
    {
      title: t("car_image"),
      field: "carImage",
      render: (rowData) =>
        rowData.carImage ? (
          <img
            alt="Car"
            src={rowData.carImage}
            style={{ width: 50, borderRadius: "50%" }}
          />
        ) : null,
      editable: "never",
      cellStyle: { textAlign: "center" },
    },
    {
      title: t("work"),
      field: "work",
      initialEditValue: "",
      cellStyle: { paddingLeft: isRTL === "rtl" ? 30 : 15 },
    },
    {
      title: t("city"),
      field: "city",
      initialEditValue: "",
      cellStyle: { paddingLeft: isRTL === "rtl" ? 30 : 15 },
    },
    {
      title: t("area"),
      field: "area",
      initialEditValue: "",
      cellStyle: { paddingLeft: isRTL === "rtl" ? 30 : 15 },
    },
    {
      title: t("payment_mode"),
      field: "paymentMode",
      initialEditValue: "",
      cellStyle: { paddingLeft: isRTL === "rtl" ? 30 : 15 },
      editable: "never",
    },
    {
      title: t("account_approve"),
      field: "approved",
      type: "boolean",
      initialEditValue: true,
      cellStyle: { textAlign: "center" },
    },
  ];
  const [selectedRow, setSelectedRow] = useState(null);

  return usersdata.loading ? (
    <CircularLoading />
  ) : (
    <MaterialTable
      title={t("agents")}
      columns={columns}
      style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
      data={data}
      onRowClick={(evt, selectedRow) =>
        setSelectedRow(selectedRow.tableData.id)
      }
      options={{
        exportButton: settings.AllowCriticalEditsAdmin,
        sorting: true,
        maxColumnSort: "all_columns",
        rowStyle: (rowData) => ({
          backgroundColor:
            selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
        }),
        editable: {
          backgroundColor: colors.Header_Text,
          fontSize: "0.8em",
          fontWeight: "bold ",
          fontFamily: 'Lucida Console", "Courier New", monospace',
        },
        headerStyle: {
          position: "sticky",
          top: "0px",
          backgroundColor: colors.DARK_BLUE,
          color: "#fff",
          fontSize: "0.8em",
          fontWeight: "bold ",
          fontFamily: 'Lucida Console", "Courier New", monospace',
        },
      }}
      localization={{
        body: {
          addTooltip: t("add"),
          deleteTooltip: t("delete"),
          editTooltip: t("edit"),
          emptyDataSourceMessage: t("blank_message"),
          editRow: {
            deleteText: t("delete_message"),
            cancelTooltip: t("cancel"),
            saveTooltip: t("save"),
          },
        },
        toolbar: {
          searchPlaceholder: t("search"),
          exportTitle: t("export"),
        },
        header: {
          actions: t("actions"),
        },
        pagination: {
          labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
          //labelRowsSelect: (t('rows')),
          firstTooltip: t("first_page_tooltip"),
          previousTooltip: t("previous_page_tooltip"),
          nextTooltip: t("next_page_tooltip"),
          lastTooltip: t("last_page_tooltip"),
        },
      }}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              checkUserExists(newData).then((res) => {
                if (res.users && res.users.length > 0) {
                  alert(t("user_exists"));
                  reject();
                } else if (res.error) {
                  alert(t("email_or_mobile_issue"));
                  reject();
                } else {
                  newData["createdByAdmin"] = true;
                  newData["usertype"] = "agent";
                  newData["createdAt"] = new Date().toISOString();
                  newData["referralId"] =
                    newData.firstName.toLowerCase() +
                    Math.floor(1000 + Math.random() * 9000).toString();
                  dispatch(addUser(newData));
                  resolve();
                }
              });
            }, 600);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              dispatch(editUser(oldData.id, newData));
            }, 600);
          }),
        onRowDelete: (oldData) =>
          settings.AllowCriticalEditsAdmin
            ? new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  dispatch(deleteUser(oldData.id));
                }, 600);
              })
            : new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  alert(t("demo_mode"));
                }, 600);
              }),
      }}
    />
  );
}
