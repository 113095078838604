import React, { useState, useEffect, useContext, useRef } from "react";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import { api } from "common";
import CircularLoading from "../components/CircularLoading";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Modal, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 1000,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const PModal = () => {
  const [open, setOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState();
  const [modalName, setModalName] = useState();
  const rootRef = useRef(null);
  const classes = useStyles();

  const { fetchModals, editModals, editModalRanges } = api;
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();

  const rtl = {
    rl: isRTL === "rtl" ? "right" : "left",
    cc: "center",
  };

  const columns = [
    {
      title: t("pmodal_name"),
      field: "name",
      render: (rowData) => <span style={{ margin: 50 }}>{rowData.name}</span>,
      cellStyle: {
        textAlign: rtl.rl,
        paddingRight: 120,
      },
      headerStyle: {
        textAlign: rtl.rl,
        paddingRight: 120,
      },
    },
  ];

  const modalColumns = [
    {
      title: t("pmodal_from"),
      field: "from",
      render: (rowData) => <span>{rowData.from}</span>,
      cellStyle: { textAlign: rtl.cc },
      headerStyle: { textAlign: rtl.rl },
    },
    {
      title: t("pmodal_to"),
      field: "to",
      render: (rowData) => <span>{rowData.to}</span>,
      cellStyle: { textAlign: rtl.cc },
      headerStyle: { textAlign: rtl.rl },
    },
    {
      title: t("pmodal_price"),
      field: "price",
      render: (rowData) => <span>{rowData.price}</span>,
      cellStyle: { textAlign: rtl.cc },
      headerStyle: { textAlign: rtl.rl },
    },
    {
      title: t("pmodal_total"),
      field: "total",
      render: (rowData) => <span>{rowData.total}</span>,
      cellStyle: { textAlign: rtl.cc },
      headerStyle: { textAlign: rtl.rl },
    },
  ];

  const settings = useSelector((state) => state.settingsdata.settings);
  const modals = useSelector((state) => state.pmodal);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchModals());
  }, [dispatch, fetchModals]);

  useEffect(() => {
    setData(modals.data || []);
  }, [modals.data]);

  const handleClose = () => {
    setOpen(false);
  };

  return modals.loading ? (
    <CircularLoading />
  ) : (
    <div ref={rootRef}>
      <MaterialTable
        title={t("pmodal_title")}
        columns={columns}
        data={data}
        icons={{
          Check: () => <>√</>,
          ThirdStateCheck: () => <>X</>,
        }}
        options={{
          exportButton: true,
          pageSize: 10,
        }}
        editable={
          settings.AllowCriticalEditsAdmin
            ? {
                isDeletable: (rowData) => !rowData.ranges,
                onRowAdd: (newData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      const tblData = data;
                      newData.value = tblData.length;
                      tblData.push(newData);
                      dispatch(editModals(tblData, "Add"));
                    }, 600);
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      const tblData = data;
                      tblData[tblData.indexOf(oldData)] = newData;
                      dispatch(editModals(tblData, "Update"));
                    }, 600);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      const tblData = data;
                      tblData.splice(tblData.indexOf(oldData), 1);
                      for (let i = 0; i < tblData.length; i++) {
                        tblData[i].value = i;
                      }
                      dispatch(editModals(tblData, "Delete"));
                    }, 600);
                  }),
              }
            : null
        }
        actions={[
          (rowData) => ({
            icon: () => (
              <div
                style={{
                  display: "flex",
                  alignItems: rtl.cc,
                  flexWrap: "wrap",
                }}
              >
                <span class="material-icons">cancel_schedule_send</span>
                {/* <CancelScheduleSendIcon /> */}
                <Typography
                  variant="subtitle2"
                  style={{ padding: 5, margin: 5 }}
                >
                  {t("pmodal_details")}
                </Typography>
              </div>
            ),
            onClick: (_, rowData) => {
              setModalName(rowData.name);
              setRowIndex(rowData.tableData.id);
              setOpen(true);
            },
          }),
        ]}
      />
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        onClose={handleClose}
        open={open}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <div className={classes.paper}>
          <MaterialTable
            title={modalName}
            columns={modalColumns}
            icons={{
              Check: () => <>√</>,
              ThirdStateCheck: () => <>X</>,
            }}
            data={
              data[rowIndex] && data[rowIndex]["ranges"]
                ? data[rowIndex]["ranges"]
                : []
            }
            options={{
              exportButton: true,
            }}
            editable={
              settings.AllowCriticalEditsAdmin
                ? {
                    onRowAdd: (newData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          let tblData = data;
                          if (!tblData[rowIndex]["ranges"]) {
                            tblData[rowIndex]["ranges"] = [];
                          }
                          tblData[rowIndex]["ranges"].push(newData);
                          dispatch(
                            editModalRanges(tblData[rowIndex]),
                            "Update"
                          );
                        }, 600);
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          let tblData = data;
                          tblData[rowIndex]["ranges"][
                            tblData[rowIndex]["ranges"].indexOf(oldData)
                          ] = newData;
                          dispatch(
                            editModalRanges(tblData[rowIndex]),
                            "Update"
                          );
                        }, 600);
                      }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          let tblData = data;
                          tblData[rowIndex]["ranges"].splice(
                            tblData[rowIndex]["ranges"].indexOf(oldData),
                            1
                          );
                          dispatch(
                            editModalRanges(tblData[rowIndex]),
                            "Delete"
                          );
                        }, 600);
                      }),
                  }
                : new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      alert(t("demo_mode"));
                    }, 600);
                  })
            }
          />
        </div>
      </Modal>
    </div>
  );
};

export default PModal;
