//saja//
// يتم احضار كود الملف كامل من الكود السابق
export function FareCalculator(
  distance,
  time,
  rateDetails,
  instructionData,
  pricingModal
) {
  let baseCalculated = 0;
  let total = 0;
  let convenienceFee = 0;
  let grand = 0;
  console.log({ distance });
  console.log({ rateDetails });
  console.log(rateDetails.convenience_fees);
  //If the car had a pmodal we use the formela below
  let modal = [];
  if (pricingModal) {
    // modal = [rateDetails.pmodal];
    console.log(pricingModal);
  }

  if (pricingModal.length) {
    pricingModal.sort(function (a, b) {
      return parseFloat(a.from) - parseFloat(b.from);
    });

    console.log("modal.length");
    console.log(pricingModal.length);
    modal = pricingModal[0];
    //based on distance we get all the ranges that the distance is higher of the 'from'
    let lessRanges = modal.ranges.filter((e) => {
      return parseFloat(distance) >= parseFloat(e.from);
    });
    //specified that distance = 1 if it was less that 1
    if (distance == 0 || distance < 1) {
      console.log(modal.ranges[0]);
      lessRanges = modal.ranges[0];
      distance = 1;
      let totalKm = 0;
      baseCalculated += (1 - totalKm) * parseFloat(lessRanges.price);
      console.log(`basecalculated ${baseCalculated} at ${lessRanges.from}`);
      console.log(`totalkm ${totalKm}`);
    } else {
      //totalkm we calculate all the km calculated
      let totalKm = 0;
      for (let i = 0; i < lessRanges.length; i++) {
        if (i == lessRanges.length - 1) {
          //if we
          baseCalculated +=
            (distance - totalKm) * parseFloat(lessRanges[i].price);
          console.log(
            `basecalculated ${baseCalculated} at ${lessRanges[i].from}`
          );
          console.log(`totalkm ${totalKm}`);
        } else {
          baseCalculated += parseFloat(lessRanges[i].total);
          console.log(
            `basecalculated ${baseCalculated} at ${lessRanges[i].from}`
          );
          totalKm =
            totalKm == parseFloat(0)
              ? totalKm + parseFloat(lessRanges[i].to)
              : totalKm +
                parseFloat(lessRanges[i].to) -
                parseFloat(lessRanges[i].from);
          console.log(`totalkm ${totalKm}`);
        }
      }
    }
    //fareCalculator with price per minute added
    console.log("time cost");
    console.log(
      parseFloat(
        rateDetails.rate_per_hour &&
          (rateDetails.rate_per_hour != null ||
            rateDetails.rate_per_hour != undefined)
          ? rateDetails.rate_per_hour
          : 1
      ) *
        (parseFloat(time) / 3600)
    );
    grand +=
      rateDetails.rate_per_hour &&
      (rateDetails.rate_per_hour != null ||
        rateDetails.rate_per_hour != undefined)
        ? parseFloat(rateDetails.rate_per_hour) * (parseFloat(time) / 3600)
        : 1 * (parseFloat(time) / 3600);
    total = baseCalculated + grand;
    console.log("UPDATED");
    convenienceFee = 0;
    if (
      rateDetails.convenience_fee_type &&
      rateDetails.convenience_fee_type == "flat"
    ) {
      convenienceFee = rateDetails.convenience_fees;
    } else {
      convenienceFee = (total * parseFloat(rateDetails.convenience_fees)) / 100;
    }

    console.log("lessRanges");
    console.log(lessRanges);
    console.log("baseCalculated");
    console.log(baseCalculated);
    console.log("total");
    console.log(total);
    console.log("grand");
    console.log(grand);
    console.log("convenience_fee");
    console.log(convenienceFee);
  }

  return {
    totalCost: parseFloat(total.toFixed(2)),
    grandTotal: parseFloat(grand.toFixed(2)),
    convenience_fees: parseFloat(convenienceFee.toFixed(2)),
  };
}
//saja//
