export const FirebaseConfig = {
	"projectId": "besunapp",
	"appId": "1:910879355614:web:b8b9c6ff9ac420f7a4c84f",
	"databaseURL": "https://besunapp-default-rtdb.firebaseio.com",
	"storageBucket": "besunapp.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDgs9SGji0u6yYZrfYOVuZH9QiDZPel6h4",
	"authDomain": "besunapp.firebaseapp.com",
	"messagingSenderId": "910879355614",
	"measurementId": "G-KM7MSSP66H"
};